<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ inverted: inverted }"
  >
    <g data-name="Layer 2">
      <g data-name="chevron-up">
        <rect
          width="24"
          height="24"
          transform="rotate(180 12 12)"
          opacity="0"
        />
        <path
          d="M16 14.5a1 1 0 0 1-.71-.29L12 10.9l-3.3 3.18a1 1 0 0 1-1.41 0 1 1 0 0 1 0-1.42l4-3.86a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.42 1 1 0 0 1-.69.28z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      inverted: { type: Boolean },
    },
  }
</script>

<style lang="scss" scoped>
  .inverted {
    transform: rotate(180deg);
  }
</style>
