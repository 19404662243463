<template>
  <div class="marking-button-wrapper">
    <button @click="goBack()" class="back-button">
      <span>&lt;</span>Tillbaka
    </button>
    <h4>Markera på kartan</h4>
    <p v-if="isMobile">
      Ange en ungefärlig adress och justera positionen genom att panorera kartan
      med två fingrar.
    </p>
    <p v-else>
      Ange en ungefärlig adress och välj position genom att klicka på kartan.
    </p>

    <div class="approximate-input">
      <label>Ungefärlig adress</label>
      <GmapAutocomplete
        v-model="approximateAddressDefault"
        placeholder="Sök ungefärlig adress"
        id="approximate-address"
        type="text"
        @focus="setApproximateAddress"
        :disabled="approximateAddress"
      />
      <SearchIcon
        class="search-icon"
        v-if="!searchQuery && !approximateAddressDefault"
      />
      <button
        @click="resetField"
        v-if="searchQuery || approximateAddressDefault"
        class="reset-field"
      >
        <CrossIcon />
      </button>
    </div>

    <div
      v-if="chosenAddress"
      :class="{ inactive: isEditing }"
      class="chosen-address"
    >
      <div>
        <label>Vald address</label>
        <p>{{ chosenAddress.formatted_address }}</p>
      </div>
    </div>

    <StandardButton
      v-if="chosenAddress"
      @click.native="confirmSelection"
      :text="'Bekräfta position'"
      class="marking-button"
      :class="{ inactive: isEditing }"
    />
  </div>
</template>

<script>
  import StandardButton from '@/components/StandardButton'
  import SearchIcon from '@/components/icons/SearchIcon'
  import CrossIcon from '@/components/icons/CrossIcon'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      searchIsMinimized: { type: Boolean },
      myMap: { type: Object },
    },
    components: {
      StandardButton,
      SearchIcon,
      CrossIcon,
    },
    computed: {
      ...mapGetters([
        'chosenAddress',
        'approximateAddress',
        'markingForField',
        'searchQuery',
        'lastActiveField',
        'isMobile',
      ]),
      isEditing() {
        return (
          !this.chosenAddress || this.lastActiveField === 'approximateAddress'
        )
      },
      approximateAddressDefault: {
        get() {
          if (this.approximateAddress === null && !this.searchQuery) {
            return ''
          }

          if (this.approximateAddress === null && this.searchQuery) {
            return this.searchQuery
          }

          return this.approximateAddress.formatted_address
        },
        set(event) {
          if (!event.srcElement.value.length) {
            this.$store.commit('setApproximateAddress', null)
          }

          this.$store.commit('setSearchQuery', event.srcElement.value)
          return
        },
      },
    },
    watch: {
      approximateAddress(newValue, oldValue) {
        if (newValue) {
          this.myMap.panTo({
            lat: newValue.geometry.location.lat,
            lng: newValue.geometry.location.lng,
          })
          this.myMap.setZoom(11)
          this.$store.commit('resetLastActiveField')
        }
      },
    },
    methods: {
      resetField() {
        this.$store.commit('setApproximateAddress', null)
        this.$store.commit('setSearchQuery', null)
      },
      goBack() {
        this.$emit('markingFinished')
        this.$store.commit('setChosenAddress', null)
      },
      setApproximateAddress() {
        this.$store.commit('setLastActiveField', 'approximateAddress')
      },
      confirmSelection() {
        let mutation =
          this.markingForField === 'destination'
            ? 'setDestinationAddress'
            : 'setOriginAddress'

        this.$store.commit(
          mutation,
          JSON.parse(JSON.stringify(this.chosenAddress))
        )
        this.$emit('markingFinished')
        this.$store.commit('setChosenAddress', null)
        this.$store.commit('setApproximateAddress', null)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  #approximate-address {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-sizing: content-box;
    line-height: 1.15;
    overflow: visible;
    font-family: inherit;
  }

  .back-button {
    border: none;
    background: none;
    font-family: 'Catamaran';
    padding: 0;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 700;
    cursor: pointer;

    span {
      font-size: 1.6rem;
      line-height: 0;
      padding: 0 7px 0 0;
      font-weight: 300;
    }
  }

  .chosen-address {
    display: flex;

    @media (max-width: $breakpoint-desktop) {
      &.inactive {
        display: none;
      }
    }

    p {
      font-weight: 700;
      font-size: 1.1rem;
    }
  }

  .approximate-input {
    position: relative;

    .search-icon,
    .reset-field {
      position: absolute;
      right: 10px;
      bottom: 15px;
      height: 20px;
      width: 20px;
    }

    .reset-field {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
    }
  }
  input:not([type]):focus:not([readonly]),
  input[type='text']:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid $base-color-light-grey;
    -webkit-box-shadow: 0 1px 0 0 $base-color-light-grey;
    box-shadow: 0 1px 0 0 $base-color-light-grey;
  }
  input[type='text']:not(.browser-default) {
    font-weight: 700;
  }
  input[type='text']:not(.browser-default)::placeholder,
  input[type='text']:not(.browser-default):disabled {
    color: $base-color-black;
  }

  .marking-button-wrapper {
    width: 100%;
    background-color: white;
    padding: 20px;
    text-align: left;

    h4 {
      margin: 0;
      padding: 10px 0;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
    }

    p {
      margin-top: 0;
      padding-right: 30px;
    }

    label {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.2px;
      font-size: 10px;
    }

    @media (min-width: $breakpoint-desktop) {
      position: relative;
      width: 400px;
    }
  }

  label,
  .back-button {
    color: $base-color-light-grey;
  }

  .marking-button {
    background-color: $base-color-blue;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.21);
  }
</style>
