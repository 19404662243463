<template>
  <div class="position-button" @click="centerOnPosition">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
      />
    </svg>
  </div>
</template>

<script>
  import { gmapApi } from 'gmap-vue'
  import geoLocationMixin from '@/mixins/geoLocationMixin'

  export default {
    mixins: [geoLocationMixin],
    computed: {
      google: gmapApi,
    },
    props: {
      myMap: { type: Object },
    },
    methods: {
      centerOnPosition() {
        this.getGeoLocation().then((coordinates) => {
          this.myMap.panTo({
            lat: coordinates.lat,
            lng: coordinates.lng,
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .position-button {
    position: absolute;
    background: $base-color-white;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      svg {
        fill: $base-color-dark-grey;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      pointer-events: none;
      fill: $base-color-medium-grey;
    }
  }
</style>
