<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0.724121C6.69676 0.724121 3.62073 3.78397 3.62073 7.56722C3.62073 12.6996 10.5 20.2758 10.5 20.2758C10.5 20.2758 17.3793 12.6996 17.3793 7.56722C17.3793 3.78397 14.3033 0.724121 10.5 0.724121ZM10.5 10.0112C9.14383 10.0112 8.04314 8.91629 8.04314 7.56722C8.04314 6.21816 9.14383 5.12326 10.5 5.12326C11.8562 5.12326 12.9569 6.21816 12.9569 7.56722C12.9569 8.91629 11.8562 10.0112 10.5 10.0112Z"
      fill="#1a73e9"
    />
  </svg>
</template>

<script>
  export default {}
</script>

<style></style>
