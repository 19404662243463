<template>
  <button
    :class="{ inactive: inactive, half: half }"
    :disabled="inactive"
    class="standard-button"
  >
    {{ text }}
  </button>
</template>

<script>
  export default {
    props: {
      text: { type: String },
      inactive: { type: Boolean },
      half: { type: Boolean },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .standard-button {
    width: 100%;
    background: $base-color-blue;
    border-radius: 25px;
    color: $base-color-white;
    padding: 15px 0;
    border: none;
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    cursor: pointer;
    line-height: 1;
  }

  .half {
    width: 50%;
  }

  @media (max-width: $breakpoint-desktop) {
    .inactive {
      display: none;
    }
  }
</style>
