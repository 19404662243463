<template>
  <div class="autocomplete">
    <button
      @click="selectSuggestion(s)"
      v-for="(s, index) in suggestions"
      :key="index"
      class="result"
    >
      {{ s.description }}
    </button>
  </div>
</template>

<script>
  import { gmapApi } from 'gmap-vue'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        suggestions: [],
      }
    },
    computed: {
      ...mapGetters(['searchQuery', 'lastActiveField']),
      google: gmapApi,
    },
    watch: {
      searchQuery(newValue, oldValue) {
        if (newValue === null || !newValue.length) {
          this.suggestions = []
          return
        }

        this.updateSuggestions()
      },
    },
    methods: {
      updateSuggestions() {
        let acService = new this.google.maps.places.AutocompleteService()
        acService.getPlacePredictions(
          { input: this.searchQuery, componentRestrictions: { country: 'se' } },
          (result, status) => {
            if (status === 'OK') {
              this.suggestions = result
            }
          }
        )
      },
      selectSuggestion(suggestion) {
        let mutation

        mutation =
          this.lastActiveField === 'destination'
            ? 'setDestinationAddress'
            : 'setOriginAddress'

        if (this.lastActiveField === 'approximateAddress') {
          mutation = 'setApproximateAddress'
        }

        this.$store.commit('resetSearchQuery')
        let geoservice = new this.google.maps.Geocoder()

        geoservice.geocode(
          { placeId: suggestion.place_id },
          (result, status) => {
            if (status === 'OK' && result.length) {
              this.$store.commit(
                mutation,
                JSON.parse(JSON.stringify(result[0]))
              )
            }
          }
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .autocomplete {
    background: $base-color-white;
    width: 100%;
  }

  .result {
    border: none;
    background: $base-color-white;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    border-bottom: 2px solid #dfdfdf;
    text-align: left;
    font-family: inherit;
    font-size: 1.05rem;
    line-height: 1.15;

    @media (max-width: $breakpoint-desktop) {
      &:nth-of-type(1n + 4) {
        display: none;
      }
    }
  }
</style>
