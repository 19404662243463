<template>
  <div class="spinner-wrapper">
    <div :class="{ small: small }" class="spinner"></div>
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
    props: {
      small: { type: Boolean },
    },
  }
</script>

<style lang="scss" scoped>
  .spinner-wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px 0;
  }
  .spinner {
    font-size: 10px;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: #348fec;
    background: -moz-linear-gradient(
      left,
      #348fec 10%,
      rgba(30, 8, 140, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      #348fec 10%,
      rgba(30, 8, 140, 0) 42%
    );
    background: -o-linear-gradient(left, #348fec 10%, rgba(30, 8, 140, 0) 42%);
    background: -ms-linear-gradient(left, #348fec 10%, rgba(30, 8, 140, 0) 42%);
    background: linear-gradient(to right, #348fec 10%, rgba(30, 8, 140, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    &.small {
      width: 3em;
      height: 3em;
    }
  }
  .spinner:before {
    width: 50%;
    height: 50%;
    background: #348fec;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .spinner:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
