<template>
  <div id="app">
    <Header :hideHeader="hideHeader" />
    <router-view />
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Header,
    },
    computed: {
      ...mapGetters(['hideHeader']),
    },
    mounted() {
      this.$store.commit('resetState')
      this.$store.commit('setWindowWidth', window.innerWidth)

      const hotjar = function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            ;(h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 2574877, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      }
      hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    },
    methods: {},
  }
</script>

<style lang="scss">
  @import '/assets/styles/_variables.scss';
  * {
    box-sizing: border-box;
    font-family: inherit;
  }
  body {
    height: 100%;
    margin: 0;
  }

  html {
    height: 100%;
    font-size: 14px;

    @media (min-width: $breakpoint-desktop) {
      height: 100vh;
    }
  }

  #app {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .pac-container {
    display: none;
  }

  .vue-map {
    min-height: 100px;
    min-width: 100px;
  }
</style>
