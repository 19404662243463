<template>
  <div
    class="route-graphic-wrapper"
    :class="{ 'extra-padding': restrictions }"
    :style="`padding: ${topPadding}px 0 ${bottomPadding}px 0`"
  >
    <div class="route-graphic">
      <div class="route-circle"><div class="dot"></div></div>
      <div class="line-wrapper"><div class="line"></div></div>
      <div class="route-circle"><div class="dot"></div></div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'RouteGraphic',
    props: {
      topPadding: { type: Number, required: true },
      bottomPadding: { type: Number, required: true },
    },
    computed: {
      ...mapGetters(['restrictions']),
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .route-graphic-wrapper {
    position: absolute;
    height: 100%;
    padding: 22px 0 20px 0;

    &.extra-padding {
      @media (max-width: $breakpoint-desktop) {
        padding: 30px 0 60px 0;
      }
    }
  }

  .route-graphic {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 16px;
    height: 100%;
  }

  .route-circle {
    border-radius: 50%;
    width: 16px;
    border: 2px solid $base-color-black;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot {
      width: 6px;
      height: 6px;
      background: $base-color-black;
      border-radius: 50%;
    }
  }

  .line-wrapper {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;

    .line {
      height: 100%;
      width: 2.2px;
      background: black;
      margin: 0 auto;
    }
  }
</style>
