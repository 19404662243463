<template>
  <header :class="{ hidden: hideHeader }">
    <div class="content-wrapper">
      <a style="width: 111px; height: 25px" href="/">
        <TunglastIcon />
      </a>
      <button @click="openMenu" class="hamburger">
        <HamburgerIcon />
      </button>
    </div>
    <NavMenu v-if="menuIsOpen" @closeMenu="closeMenu" />
  </header>
</template>

<script>
  import TunglastIcon from '@/components/icons/TunglastIcon'
  import HamburgerIcon from '@/components/icons/HamburgerIcon'
  import NavMenu from '@/components/NavMenu'

  export default {
    components: {
      HamburgerIcon,
      TunglastIcon,
      NavMenu,
    },
    props: {
      hideHeader: { type: Boolean },
    },
    data() {
      return {
        menuIsOpen: false,
      }
    },
    methods: {
      openMenu() {
        this.menuIsOpen = true
      },
      closeMenu() {
        this.menuIsOpen = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  header {
    height: 60px;
    background: white;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid $base-color-grey;

    a {
      line-height: 0;
    }

    &.hidden {
      @media (max-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
  }

  .hamburger {
    position: absolute;
    right: 0;
    border: 0;
    background: none;
    padding: 10px;
    cursor: pointer;
  }
</style>
