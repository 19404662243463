export default {
  methods: {
    getGeoLocation() {
      return new Promise((resolve, reject) => {
        this.$getLocation({})
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getReverseGeocode(lat, lng) {
      return new Promise((resolve, reject) => {
        let geoservice = new this.google.maps.Geocoder()
        geoservice.geocode(
          {
            location: {
              lat: lat,
              lng: lng,
            },
          },
          (results, status) => {
            if (status === 'OK') {
              resolve(results)
            } else {
              reject(status)
            }
          }
        )
      })
    },
  },
}
