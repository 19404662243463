<template>
  <div class="searchbox">
    <div class="searchbox-header">
      <h4
        v-if="!restrictions && !isSearching"
        :class="{ minimized: searchIsMinimized }"
      >
        Ange din rutt
      </h4>
      <h4 v-else :class="{ minimized: searchIsMinimized }">Din rutt</h4>
      <div class="new-search">
        <button v-if="restrictions" @click="newSearch">
          Gör en ny sökning
        </button>
      </div>
      <button
        v-if="restrictions"
        @click="$emit('toggleMinimized')"
        class="close-button"
      >
        <ChevronIcon :inverted="!searchIsMinimized" />
      </button>
    </div>

    <div class="search-fields">
      <RouteGraphic :topPadding="30" :bottomPadding="20" />

      <div class="search-inputs">
        <label>start</label>
        <GmapAutocomplete
          v-model="originDefaultValue"
          placeholder="Välj start"
          id="origin"
          type="text"
          @focus="$emit('setActiveField', 'origin')"
          :options="{ componentRestrictions: { country: 'se' } }"
          :disabled="restrictions || isSearching || markingOnMap"
        />
        <label>mål</label>
        <GmapAutocomplete
          v-model="destinationDefaultValue"
          placeholder="Välj mål"
          id="destination"
          type="text"
          @focus="$emit('setActiveField', 'destination')"
          :options="{ componentRestrictions: { country: 'se' } }"
          :disabled="restrictions || isSearching || markingOnMap"
        />
      </div>
      <div class="new-search mobile">
        <button v-if="restrictions" @click="newSearch">
          Gör en ny sökning
        </button>
      </div>
    </div>

    <StandardButton
      v-if="
        !restrictions && !isSearching && !markingOnMap && bothRoutesSelected
      "
      @click.native="search"
      :text="'Få vägbeskrivning'"
    />

    <RoadSigns v-if="showRoadSigns" />

    <p v-if="restrictions && !searchIsMinimized" class="mail-instructions">
      Få mer detaljerad information om rutten skickad till din mail.
    </p>

    <StandardButton
      v-if="submitSuccessful || showPDFButton"
      style="margin-bottom: 15px"
      :text="'Ladda ner PDF'"
      @click.native="startDownload"
    />

    <StandardButton
      v-if="restrictions && !isSearching && !searchIsMinimized"
      :text="'Maila rutten'"
      @click.native="$emit('openingModal')"
    />

    <label
      class="search-terms"
      v-if="restrictions && !isSearching && !searchIsMinimized"
    >
      <span
        >Vilket ansvar har Tunglast.se? Läs våra
        <StandardButton
          :text="'villkor'"
          class="terms-text"
          @click.native="$emit('openTerms')"
      /></span>
    </label>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import RoadSigns from '@/components/RoadSigns'
  import StandardButton from '@/components/StandardButton'
  import ChevronIcon from '@/components/icons/ChevronIcon'
  import RouteGraphic from '@/components/RouteGraphic'

  export default {
    name: 'SearchBox',
    components: {
      RoadSigns,
      StandardButton,
      ChevronIcon,
      RouteGraphic,
    },
    props: {
      myMap: { type: Object },
      searchIsMinimized: { type: Boolean },
    },
    computed: {
      ...mapGetters([
        'originAddress',
        'destinationAddress',
        'restrictions',
        'lastActiveField',
        'isSearching',
        'markingOnMap',
        'submitSuccessful',
        'isMobile',
        'userHasSubmitted'
      ]),
      showPDFButton() {
        return (this.$route.query.p === 'true' || this.userHasSubmitted) &&  this.restrictions
      },
      showRoadSigns() {
        if (this.searchIsMinimized && this.isMobile) {
          return false
        }

        if (this.restrictions || this.isSearching) {
          return true
        }

        return false
      },
      bothRoutesSelected() {
        return this.originAddress && this.destinationAddress
      },
      originDefaultValue: {
        get() {
          if (this.originAddress === null) return ''

          if (!this.originAddress.formatted_address) {
            return `${this.originAddress.geometry.location.lat}, ${this.originAddress.geometry.location.lng}`
          }

          return this.originAddress.formatted_address
        },
        set(event) {
          if (!event.srcElement.value.length) {
            this.$store.commit('setOriginAddress', null)
          }

          this.$store.commit('setSearchQuery', event.srcElement.value)
          return
        },
      },
      destinationDefaultValue: {
        get() {
          if (this.destinationAddress === null) return ''

          if (!this.destinationAddress.formatted_address) {
            return `${this.destinationAddress.geometry.location.lat}, ${this.destinationAddress.geometry.location.lng}`
          }

          return this.destinationAddress.formatted_address
        },
        set(event) {
          if (!event.srcElement.value.length) {
            this.$store.commit('setDestinationAddress', null)
          }

          this.$store.commit('setSearchQuery', event.srcElement.value)
          return
        },
      },
    },
    methods: {
      startDownload() {
        this.$store.commit('setStartDownload', true)
      },
      search() {
        if (this.originAddress === null || this.destinationAddress === null)
          return

        this.$emit('search')
        this.$store.commit('resetLastActiveField')
      },
      newSearch() {
        window.location = '/'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .searchbox {
    background: $base-color-white;
    padding: 10px 20px 20px 20px;
    position: relative;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.21);
    overflow: hidden;

    @media (max-width: $breakpoint-mobile) {
      padding: 20px;
    }
  }

  .searchbox-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 20px 0;

    h4 {
      margin: 0;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      color: $base-color-black;

      &.minimized {
        display: block;
        @media (max-width: $breakpoint-desktop) {
          display: none;
        }
      }
    }
    @media (max-width: $breakpoint-desktop) {
      padding: 0 0 20px 0;
    }

    .close-button {
      padding: 5px;
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 10;

      @media (min-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  .search-fields {
    position: relative;
  }

  .new-search {
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: $breakpoint-desktop) {
      display: none;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
      text-decoration: underline;
      padding: 5px;
      font-size: 0.8rem;
    }

    &.mobile {
      display: flex;
      @media (min-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  .search-inputs {
    padding-left: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #origin {
      margin-bottom: 25px;
    }

    #destination {
      margin-bottom: 8px;
    }

    #origin,
    #destination {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      border-radius: 0;
      outline: none;
      height: 3rem;
      width: 100%;
      font-size: 16px;
      padding: 0;
      box-sizing: content-box;
      line-height: 1.15;
      overflow: visible;
    }

    label {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.2px;
      font-size: 10px;
    }
    ::placeholder {
      color: $base-color-black;
      font-weight: 700;
    }
  }

  .marking-button {
    background-color: $base-color-blue;
  }

  .mail-instructions {
    text-align: left;
    font-size: 1rem;
    color: $base-color-black;
    border-top: solid $base-color-grey 1px;
    padding: 20px 0;
    margin: 0;
  }

  input[type='text']:not(.browser-default):focus:not([readonly]) + label {
    color: #9e9e9e;
  }
  input:not([type]):focus:not([readonly]),
  input[type='text']:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid $base-color-light-grey;
    -webkit-box-shadow: 0 1px 0 0 $base-color-light-grey;
    box-shadow: 0 1px 0 0 $base-color-light-grey;
  }
  .marking-button-wrapper {
    z-index: 10;
    width: 100%;
    background-color: white;
    text-align: left;

    h4 {
      text-align: left;
      font-size: 20px;
      font-weight: 700;
    }

    label {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.2px;
      font-size: 10px;
      margin-bottom: 20px;
    }

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
  .search-inputs #origin,
  .search-inputs #destination {
    font-weight: 700;
    color: $base-color-black;
  }

  .search-terms {
    color: #333333;
    font-size: 10px;
  }
  .terms-text {
    text-decoration: underline;
    color: $base-color-black !important;
    border: none;
    width: auto;
    background: none;
    color: #ffffff;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 10px;
    font-weight: 200;
    letter-spacing: 0.3px;
    cursor: pointer;
  }
</style>
