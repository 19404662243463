<template>
  <div class="signs-container">
    <h5 class="loading-text" v-if="isSearching">Bearbetar trafikdata</h5>
    <Spinner v-if="isSearching" />
    <ul class="roadsign__container" v-if="restrictions">
      <li v-if="restrictions.roadWidth">
        <BaseSign
          :classes="['square-sign', 'fri-bredd']"
          :label="`${restrictions.roadWidth.toFixed(1).replace('.', ',')} m`"
          :text="'Begränsning av fri bredd på väg'"
        />
      </li>
      <li v-if="restrictions.vehicleWidth">
        <BaseSign
          :classes="['sign', 'fordonsbredd']"
          :label="`${restrictions.vehicleWidth.toFixed(1).replace('.', ',')}`"
          :text="'Begränsad fordonsbredd'"
        />
      </li>
      <li v-if="restrictions.vehicleLength">
        <BaseSign
          :classes="['sign', 'fordonslangd']"
          :label="`${restrictions.vehicleLength.toFixed(1).replace('.', ',')}`"
          :text="'Begränsad fordonslängd'"
        />
      </li>
      <li v-if="restrictions.axlePressure">
        <BaseSign
          :classes="['sign', 'axeltryck']"
          :label="`${restrictions.axlePressure.toFixed(1).replace('.', ',')} t`"
          :text="'Begränsat axeltryck'"
        />
      </li>
      <li v-if="restrictions.bogiePressure">
        <BaseSign
          :classes="['sign', 'boggitryck']"
          :label="`${restrictions.bogiePressure
            .toFixed(1)
            .replace('.', ',')} t`"
          :text="'Begränsad boogietryck'"
        />
      </li>
      <li v-if="restrictions.bearingCapacityClass">
        <BaseSign
          :classes="['sign', 'barighetsklass']"
          :label="`${restrictions.bearingCapacityClass}`"
          :text="'Bärighetsklass'"
        />
      </li>
      <li v-if="restrictions.grossWeight">
        <BaseSign
          :classes="['sign', 'bruttovikt']"
          :label="`${restrictions.grossWeight.toFixed(1).replace('.', ',')} t`"
          :text="'Begränsad bruttovikt på fordon'"
        />
      </li>
      <li v-if="restrictions.environmentZone">
        <BaseSign
          :classes="['sign', 'miljozon']"
          :label="`${restrictions.environmentZone.replace('Miljözon k', 'K')}`"
          :text="'Klass på miljözon'"
        />
      </li>
      <li v-if="restrictions.heightObstacles">
        <BaseSign
          :classes="['sign', 'fordonshojd']"
          :label="`${restrictions.heightObstacles
            .toFixed(1)
            .replace('.', ',')} m`"
          :text="'Begränsad fordonshöjd'"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Spinner from '@/components/Spinner'
  import BaseSign from '@/components/BaseSign'

  export default {
    name: 'roadsigns',
    components: {
      Spinner,
      BaseSign,
    },
    computed: {
      ...mapGetters(['restrictions', 'isSearching']),
    },
  }
</script>

<style lang="scss">
  @import '../assets/styles/_variables.scss';
  :root {
    --roadsignsize: 75px;
    @media (max-width: $breakpoint-mobile) {
      --roadsignsize: 55px;
    }
  }

  .signs-container {
    min-height: 145px;
    max-height: 40vh;
    overflow: auto;
  }
  .loading-text {
    font-weight: 700;
    font-size: 1.3rem;
    margin: 1rem 0;
  }
  .roadsign__container {
    display: flex;
    flex-direction: column;
    padding: 0;

    li {
      margin: 16px 0;
      display: flex;
      align-items: center;
    }
  }
</style>
