<template>
  <div id="footer">
    <div class="footer__logo">
      <TunglastIconWhite />
    </div>
    <p class="copy">Copyright/Tunglast 2021. All rights reserved</p>
  </div>
</template>

<script>
  import TunglastIconWhite from '@/components/icons/TunglastIcon-white'
  export default {
    name: 'footer',
    components: {
      TunglastIconWhite,
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';
  #footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background-color: #252525;
    color: white;
    padding: 12px 55px;

    @media (max-width: $breakpoint-mobile) {
      padding: 12px;
    }

    .footer__logo {
      height: 25px;
      width: 125px;
    }
  }
  .footer-tunglast {
    font-size: 24px;
    margin: 0;
  }
  .copy {
    font-size: 0.8rem;
  }
</style>
