<template>
  <div class="mail-chimp-modal">
    <div
      class="form-wrapper"
      v-if="!submitSuccessful"
      v-mousedown-outside="() => clickOutside()"
    >
      <div class="form-header">
        <div>
          <h2 class="left-align rubrik">Uppgifter</h2>
        </div>
        <div class="close-button">
          <CrossIcon @click.native="clickOutside()" />
        </div>
      </div>
      <form class="form">
        <div class="row">
          <div class="input-field">
            <label for="first_name">FÖRNAMN</label>
            <input
              :class="{ invalid: !firstNameIsValid }"
              v-model="firstName"
              name="MERGE1"
              type="text"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <label for="last_name">EFTERNAMN</label>
            <input
              :class="{ invalid: !lastNameIsValid }"
              v-model="lastName"
              name="MERGE2"
              type="text"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <label for="MERGE0">E-POST</label>
            <input
              :class="{ invalid: !emailIsValid }"
              v-model="email"
              name="MERGE0"
              type="email"
              required
            />
          </div>
        </div>
        <div class="row">
          <div
            :class="{ invalid: !transportTypeIsValid }"
            class="input-field transport-type"
          >
            <label class="active" for="MERGE5">TYP AV TRANSPORT</label>
            <select v-model="transportType" name="MERGE5" class="select">
              <option value="null" disabled selected>
                Välj typ av transport
              </option>
              <option value="Maskintransport">Maskintransport</option>
              <option value="Godstransport">Godstransport</option>
              <option value="Anläggningsbil">Anläggningsbil</option>
              <option value="Övriga transporter">Övriga transporter</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <label for="MERGE3">FÖRETAG</label>
            <input v-model="companyName" name="MERGE3" type="text" />
          </div>
        </div>
        <div class="terms left-align">
          <label class="checkbox">
            <input v-model="checked" type="checkbox" />
            <span>Jag godkänner</span>
          </label>
          <button @click="(event) => openTerms(event)" class="terms-button">
            villkoren
          </button>
        </div>
        <StandardButton
          @click.native="(event) => submitForm(event)"
          :text="'Skicka'"
        />
      </form>
    </div>
    <div v-else class="thank-you" v-mousedown-outside="() => clickOutside()">
      <div class="thank-you-header">
        <div class="close-button">
          <CrossIcon @click.native="clickOutside()" />
        </div>
      </div>
      <h2>Tack!</h2>
      <p>Det dyker snart upp ett mail i din inkorg.</p>

      <StandardButton
        :text="'Ladda ner PDF'"
        @click.native="$emit('download')"
        :half="true"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import StandardButton from '@/components/StandardButton'
  import CrossIcon from '@/components/icons/CrossIcon'

  export default {
    name: 'MailChimpModal',
    components: {
      StandardButton,
      CrossIcon,
    },
    data() {
      return {
        email: null,
        firstName: null,
        lastName: null,
        transportType: null,
        companyName: null,
        checked: null,
      }
    },
    computed: {
      ...mapGetters([
        'originAddress',
        'destinationAddress',
        'submitSuccessful',
      ]),
      emailIsValid() {
        if (this.email === null) return true

        return this.email.match(/.+@.+\..+/)
      },
      firstNameIsValid() {
        if (this.firstName === null) return true

        return this.firstName.length > 0
      },
      lastNameIsValid() {
        if (this.lastName === null) return true

        return this.lastName.length > 0
      },
      transportTypeIsValid() {
        if (this.transportType === null) return true

        if (this.transportType === 'null') return false

        return this.transportType.length > 0
      },
      checkboxValid() {
        if (this.checked === null) return true

        return this.checked
      },
    },

    methods: {
      openTerms(e) {
        e.preventDefault()
        this.$emit('openTerms')
      },
      clickOutside() {
        this.$emit('close')
      },
      submitForm(event) {
        event.preventDefault()
        let fields = [this.firstName, this.lastName, this.transportType]
        let valid =
          fields.every((p) => p && p.length > 0 && p !== 'null') &&
          this.checked &&
          this.email

        if (!valid) {
          this.firstName = this.firstName || ''
          this.lastName = this.lastName || ''
          this.transportType = this.transportType || 'null'
          this.email = this.email || ''
          if (this.checked === null) {
            this.checked = false
          }

          return
        }

        axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          url: `https://${process.env.VUE_APP_TUNGLAST_API_BASE}/api/v1/subscribe`,
          data: {
            Email: this.email,
            FirstName: this.firstName,
            LastName: this.lastName,
            Origin: this.originAddress.formatted_address,
            Destination: this.destinationAddress.formatted_address,
            TransportType: this.transportType,
            CompanyName: this.companyName || '',
            Uri: window.location.href.replace('p=false', 'p=true'),
          },
        }).then((result) => {
          if (result.status === 200 && result.data.success) {
            this.$store.commit('setSubmitSuccessful', true)
            this.$store.commit('setUserHasSubmitted', true)
            this.email = null
            this.firstName = null
            this.transportType = null
            this.lastName = null
            this.companyName = null
            this.checked = null
          }
        })
      },
      openingModal() {
        this.$store.commit('setSubmitSuccessful', false)
      },
    },
  }
</script>

<style lang="scss">
  @import '../assets/styles/_variables.scss';
  .mail-chimp-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-wrapper {
    background: $base-color-white;
    padding: 15px 35px 40px 35px;
    width: 90%;
    max-width: 700px;
  }

  .form-header,
  .thank-you-header {
    position: relative;
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .input-field {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 0;

    label {
      width: 100%;
      text-align: left;
      font-size: 12px;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $base-color-grey;
      border-radius: 0;
      outline: none;
      height: 2rem;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0 0 5px;
      box-sizing: content-box;
      line-height: 1.15;
      overflow: visible;

      &:focus {
        box-shadow: 0px 1px 0px $base-color-light-grey;
      }
    }
  }

  .invalid {
    input,
    select {
      background: rgba(255, 0, 0, 0.3);
    }
  }

  .select,
  .select:focus,
  .select:focus-visible {
    border: none;
    border-bottom: 1px solid $base-color-grey;
    outline: none;
    padding: 5px 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
  }

  .left-align {
    text-align: left;
  }

  .terms {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .terms-button {
    background: none;
    border: none;
    cursor: pointer;
    color: $base-color-blue;
    font-weight: bold;
    font-family: inherit;
    padding: 0;
    text-decoration: underline;
    padding: 0.5rem 0;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    align-items: center;
  }

  .checkbox > span {
    color: #34495e;
    padding: 0.5rem 0.25rem;
  }

  .checkbox > input {
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 2px solid #34495e;
    outline: none;
    transition-duration: 0.3s;
    background-color: $base-color-white;
    cursor: pointer;
  }

  .checkbox > input:checked + span::before {
    content: ' ';
    display: block;
    // text-align: center;
    color: $base-color-black;
    position: absolute;
    left: 0.8rem;
    top: 0.75rem;
    border: 1px solid black;
    border-width: 0 2px 2px 0;
    height: 10px;
    width: 5px;
    transform: rotate(40deg);
  }

  .checkbox > input:active {
    border: 2px solid #34495e;
  }

  .thank-you {
    padding: 25px 35px 40px;
    width: 90%;
    max-width: 700px;
    background: $base-color-white;
  }
</style>
