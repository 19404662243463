<template>
  <div id="faq">
    <div class="hero"></div>
    <section class="blog">
      <h3>Vanliga frågor</h3>
      <p>
        Här kan du få svar på de vanligaste frågorna om Tunglast. Är det någon
        fråga vi missat? Maila oss på
        <a href="mailto: kundtjanst@pinpointer.se">kundtjanst@pinpointer.se</a>
      </p>
      <ol>
        <li>
          Är det gratis att använda Tunglast?
          <p>
            Vi kommer att lansera plustjänster under året vilka kommer att
            innebära kostnader om du väljer dem. Men det är helt gratis att
            använda den första delen av sökningen, du kan även få rutten sänd
            till dig i Pdf gratis (max tre förfrågningar per månad).
          </p>
        </li>
        <li>
          Vad behöver jag göra för att komma igång med tjänsten?
          <p>
            Vill du ha Pdf sänt till din mejl så behöver du lämna din
            mejladress.
          </p>
        </li>
        <li>
          Hur använder jag tjänsten?
          <p>
            Gör din sökning i sökrutan genom att skriva in, ange din position
            eller droppa en pin. Efter det så räknar Tunglast ut din resa.
          </p>
        </li>
        <li>
          Kan jag lita på att rutten stämmer?
          <p>Tunglast använder Trafikverkets officiella vägdata.</p>
        </li>
        <li>
          Hur ser ni till att all information om vikt och bredd är
          korrekt/uppdaterad?
          <p>
            Genom en koppling till Trafikverkets officiella databas, som
            uppdateras flera gånger per dygn.
          </p>
        </li>
        <li>
          Kan vem som helst använda Tunglast?
          <p>
            Ja, vem som helst med tillgång till Internet kan använda Tunglast.
          </p>
        </li>
        <li>
          Varför erbjuder Pinpointer den här tjänsten?
          <p>
            Vi såg ett stort behov av digitalisering av transportplaneringen i
            vårt arbete med utvecklingen av Pinpointer. Att minimera våra
            transporters klimatpåverkan samtidigt som vi förenklar arbetet är
            grundbultarna i systemet. Därför valde vi att möjliggöra för fler
            att planera tunga transporter på ett smartare sätt.
          </p>
        </li>
      </ol>
      <div class="img"></div>
    </section>
    <ArticleFooter />
  </div>
</template>
<script>
  import ArticleFooter from '../components/ArticleFooter'
  export default {
    name: 'Faq',
    components: {
      ArticleFooter,
    },
  }
</script>
<style scoped lang="scss">
  @import '../assets/styles/_variables.scss';
  #faq {
    width: 100vw;
    max-width: 100%;
    position: relative;
    .hero {
      width: 100%;
      height: 600px;
      background-image: url('../assets/images/hero-lastbilar.jpg');
      background-repeat: no-repeat;
      background-color: gray;
      background-size: cover;
      @media (max-width: $breakpoint-mobile) {
        height: 250px;
        background-image: url('../assets/images/hero1-mobil.jpg');
      }
    }
    .blog {
      height: auto;
      width: 926px;
      margin: 0 auto 10rem auto;
      text-align: left;
      @media (max-width: $breakpoint-mobile) {
        padding: 2rem;
        width: 100%;
      }

      .img {
        width: 100%;
        height: 385px;
        background-image: url('../assets/images/bild1-desktop.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgb(187, 187, 187);
        background-size: cover;
        margin-top: 3rem;
        @media (max-width: $breakpoint-mobile) {
          height: 200px;
          background-image: url('../assets/images/bild1-mobil.jpg');
        }
      }
      h3 {
        font-weight: $font-bold;
        font-size: $font-size-l;
      }
      p {
        font-size: 18px;
      }
      ol {
        padding-left: 15px;
        li {
          font-size: 18px;
          margin: 15px 0;
          font-weight: 700;

          p {
            font-weight: 200;
          }
        }
      }
    }
  }
</style>
