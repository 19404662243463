<template>
  <div class="sign-wrapper">
    <div :class="classes">
      <span class="label">{{ label }}</span>
    </div>
    <p class="sign-text">{{ text }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      classes: { type: Array },
      label: { type: String },
      text: { type: String },
    },
  }
</script>

<style lang="scss">
  @import '../assets/styles/_variables.scss';
  :root {
    --roadsignsize: 75px;
    @media (max-width: $breakpoint-mobile) {
      --roadsignsize: 55px;
    }
  }

  .sign-text {
    font-family: $font-family;
  }

  .sign-wrapper {
    display: flex;
    align-items: center;
  }

  .square-sign {
    width: var(--roadsignsize);
    height: calc(var(--roadsignsize) * 0.4);
    position: relative;
    background-position: center center;
    background-size: cover;
    margin-right: 12px;

    &.pdf {
      width: 45px;
      height: calc(45px * 0.4);
    }
  }

  .sign {
    width: var(--roadsignsize);
    height: var(--roadsignsize);
    position: relative;
    background-size: contain;
    margin-right: 12px;

    &.pdf {
      width: 45px;
      height: 45px;
    }
  }

  .fri-bredd {
    background-image: url('../assets/images/fri-bredd.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.1);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.2);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.1);
        font-size: calc(45px * 0.2);
      }
    }
  }

  .fordonsbredd {
    background-image: url('../assets/images/begransad-fordonsbredd.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.38);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.2);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.38);
        font-size: calc(45px * 0.2);
      }
    }
  }

  .fordonsbredd .fordonshojd {
    background-image: url('../assets/images/begransad-fordonshojd.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.38);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.2);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.38);
        font-size: calc(45px * 0.2);
      }
    }
  }

  .fordonslangd {
    background-image: url('../assets/images/begransad-fordonslangd.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.5);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.15);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.5);
        font-size: calc(45px * 0.15);
      }
    }
  }

  .axeltryck {
    background-image: url('../assets/images/begransat-axeltryck.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.2);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.3);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.2);
        font-size: calc(45px * 0.3);
      }
    }
  }

  .boggitryck {
    background-image: url('../assets/images/begransat-boggitryck.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.2);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.3);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.38);
        font-size: calc(45px * 0.2);
      }
    }
  }

  .barighetsklass {
    background-image: url('../assets/images/barighetsklass.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.55);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.2);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.55);
        font-size: calc(45px * 0.2);
      }
    }
  }

  .miljozon {
    background-image: url('../assets/images/miljozon.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.75);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.15);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.75);
        font-size: calc(45px * 0.15);
      }
    }
  }

  .bruttovikt {
    background-image: url('../assets/images/begransad-bruttovikt.png');

    .label {
      position: absolute;
      top: calc(var(--roadsignsize) * 0.33);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: calc(var(--roadsignsize) * 0.3);
      font-family: $font-roadsign;
      font-weight: bold;
    }

    &.pdf {
      .label {
        top: calc(45px * 0.33);
        font-size: calc(45px * 0.3);
      }
    }
  }

  .bruttovikt .label {
    color: black;
    line-height: initial;
  }
</style>
