import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import * as GmapVue from 'gmap-vue'
import App from './App'
import router from './router'
import VueGtag from 'vue-gtag'
import createPersistedState from 'vuex-persistedstate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import VueGeolocation from 'vue-browser-geolocation'
import vOutsideEvents from 'vue-outside-events'

library.add(faInstagram, faLinkedin)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(vOutsideEvents)
Vue.use(VueGeolocation)
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_TUNGLAST_GA_ID },
  },
  router
)

Vue.use(Vuex)
Vue.use(GmapVue, {
  load: {
    region: 'SE',
    language: 'SV',
    key: 'AIzaSyCIke2vQjdAE8DXq5Cq9GzewKrj_NAgT6Q',
    libraries: 'places, directions',
  },
  installComponents: true,
})

const store = new Vuex.Store({
  state: {
    originAddress: null,
    destinationAddress: null,
    legs: null,
    restrictions: null,
    isSearching: false,
    searchQuery: null,
    lastActiveField: '',
    markingOnMap: false,
    completedSearchIsMinimized: false,
    markingForField: null,
    chosenAddress: null,
    approximateAddress: null,
    submitSuccessful: false,
    windowWidth: null,
    directions: null,
    restrictionsPerRoute: null,
    cutoffStepIndex: null,
    startDownload: false,
    userHasSubmitted: false,
  },
  getters: {
    originAddress: (state) => state.originAddress,
    destinationAddress: (state) => state.destinationAddress,
    isSearching: (state) => state.isSearching,
    legs: (state) => state.legs,
    restrictions: (state) => state.restrictions,
    searchQuery: (state) => state.searchQuery,
    lastActiveField: (state) => state.lastActiveField,
    markingOnMap: (state) => state.markingOnMap,
    completedSearchIsMinimized: (state) => state.completedSearchIsMinimized,
    hideHeader: (state) => {
      if (state.restrictions) {
        return false
      }

      return state.markingOnMap || state.lastActiveField.length > 0
    },
    chosenAddress: (state) => state.chosenAddress,
    approximateAddress: (state) => state.approximateAddress,
    markingForField: (state) => state.markingForField,
    submitSuccessful: (state) => state.submitSuccessful,
    windowWidth: (state) => state.windowWidth,
    directions: (state) => state.directions,
    restrictionsPerRoute: (state) => state.restrictionsPerRoute,
    isDesktop: (state) => {
      if (!state.windowWidth) {
        return false
      }
      return state.windowWidth >= 1024
    },
    isMobile: (state) => {
      if (!state.windowWidth) {
        return false
      }
      return state.windowWidth < 1024
    },
    cutoffStepIndex: (state) => state.cutoffStepIndex,
    startDownload: (state) => state.startDownload,
    userHasSubmitted: (state) => state.userHasSubmitted
  },
  mutations: {
    setUserHasSubmitted(state, payload) {
      state.userHasSubmitted = payload
    },
    setStartDownload(state, payload) {
      state.startDownload = payload
    },
    setCutoffStepIndex(state, payload) {
      state.cutoffStepIndex = payload
    },
    setRestrictionsPerRoute(state, payload) {
      state.restrictionsPerRoute = payload
    },
    setDirections(state, payload) {
      let directionsObject = {
        duration: payload.legs[0].duration.text,
        distance: payload.legs[0].distance.text,
      }
      let steps = payload.legs[0].steps.map((step) => {
        return {
          distance: step.distance.text,
          duration: step.duration.text,
          instructions: step.instructions,
          maneuver: step.maneuver,
        }
      })
      directionsObject.steps = steps
      state.directions = directionsObject
    },
    setWindowWidth(state, payload) {
      state.windowWidth = payload
    },
    setSubmitSuccessful(state, payload) {
      state.submitSuccessful = payload
    },
    setApproximateAddress(state, payload) {
      state.approximateAddress = payload
    },
    setChosenAddress(state, payload) {
      state.chosenAddress = payload
    },
    setMarkingForField(state, payload) {
      state.markingForField = payload
    },
    setCompletedSearchIsMinimized(state, payload) {
      state.completedSearchIsMinimized = payload
    },
    setMarkingOnMap(state, payload) {
      state.markingOnMap = payload
    },
    setOriginAddress(state, payload) {
      state.originAddress = payload
    },
    setDestinationAddress(state, payload) {
      state.destinationAddress = payload
    },
    setLegs(state, payload) {
      let polylineObject = {
        Legs: [],
      }
      payload.legs.forEach((leg) => {
        polylineObject.Legs.push({
          Steps: leg.steps.map((s) => {
            return {
              PathLine: s.polyline.points,
            }
          }),
        })
      })

      state.legs = polylineObject
    },
    setRestrictions(state, payload) {
      state.restrictions = payload
    },
    setIsSearching(state, payload) {
      state.isSearching = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    resetSearchQuery(state) {
      state.searchQuery = null
    },
    setLastActiveField(state, payload) {
      state.lastActiveField = payload
    },
    resetLastActiveField(state) {
      state.lastActiveField = ''
    },
    resetState(state) {
      state.originAddress = null
      state.destinationAddress = null
      state.legs = null
      state.restrictions = null
      state.isSearching = false
      state.searchQuery = null
      state.lastActiveField = ''
      state.completedSearchIsMinimized = false
      state.markingOnMap = false
      state.markingForField = null
      state.chosenAddress = null
      state.approximateAddress = null
      state.submitSuccessful = false
      state.windowWidth = null
      state.directions = null
      state.restrictionsPerRoute = null
      state.cutoffStepIndex = null
      state.startDownload = false
      state.userHasSubmitted = false
    },
  },
  actions: {
    getRestrictions({ getters, commit }) {
      commit('setRestrictions', null)
      commit('setIsSearching', true)
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        url: `${window.location.protocol}//${process.env.VUE_APP_TUNGLAST_API_BASE}/api/v1/restrictions/limited/google`,
        data: getters.legs,
      })
        .then((response) => {
          commit('setRestrictions', response.data.result),
            commit('setIsSearching', false)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getRestrictionsPerRoute({ getters, commit }) {
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        url: `https://${process.env.VUE_APP_TUNGLAST_API_BASE}/api/v1/restrictions/google`,
        data: getters.legs,
      })
        .then((response) => {
          commit('setRestrictionsPerRoute', response.data.result)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  modules: {},
  plugins: [createPersistedState({ storage: window.sessionStorage })],
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
