<template>
  <VueHtml2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    filename="Tunglast-rutt"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-content-width="800px"
    ref="html2Pdf"
    @beforeDownload="beforeDownload($event)"
  >
    <section slot="pdf-content">
      <div class="pdf-template">
        <header>
          <h3 class="page-heading">Din rutt</h3>
          <p>
            Rutt skapad <b>{{ currentDate }}</b> på tunglast.se
          </p>
        </header>
        <div class="results">
          <RouteGraphic :topPadding="25" :bottomPadding="20" />

          <div class="routes">
            <div class="start">
              <p class="route-label">START</p>
              <h4 class="route-address">
                {{ originAddress.formatted_address }}
              </h4>
              <div class="bottom-border"></div>
            </div>
            <div class="destination">
              <p class="route-label">MÅL</p>
              <h4 class="route-address">
                {{ destinationAddress.formatted_address }}
              </h4>
              <div class="bottom-border"></div>
            </div>
          </div>
        </div>
        <ul class="signs">
          <li v-for="(sign, index) in signs" :key="index">
            <BaseSign
              :text="sign.text"
              :label="sign.label"
              :classes="sign.classes.concat(['pdf'])"
            />
          </li>
        </ul>
        <div class="section-divider"></div>
        <div class="directions">
          <div class="origin">
            <h3 class="locality">
              {{ originSubLocality }}
            </h3>
            <small class="town">{{ originPostalTown }}</small>
          </div>
          <div class="direction-steps">
            <div class="step-divider">
              <div class="line"></div>
              <p class="distance">
                {{ directions.duration }} ({{ directions.distance }})
              </p>
            </div>
            <div
              class="step"
              :class="[
                pageBreak(index) ? 'html2pdf__page-break' : '',
                pageBreak(index - 1) ? 'extra-margin' : '',
              ]"
              v-for="(step, index) in directions.steps"
              :key="index"
            >
              <p>
                <span>{{ index + 1 }}. </span>
                <span class="instruction" v-html="step.instructions"></span>
              </p>
              <BaseSign
                v-for="(sign, x) in stepRestrictionSigns(index)"
                :key="`step-sign-${x}`"
                :text="sign.text"
                :label="sign.label"
                :classes="sign.classes.concat(['pdf'])"
              />
              <div class="step-divider">
                <div class="line"></div>
                <p class="distance">{{ step.distance }}</p>
              </div>
            </div>
          </div>
          <div class="destination">
            <h3 class="locality">
              {{ destinationSubLocality }}
            </h3>
            <small class="town">{{ destinationPostalTown }}</small>
          </div>
          <div class="step-divider">
            <div class="line"></div>
          </div>
          <p>Tack för att du valt Tunglast när du planerat din transport.</p>
          <p>
            De här vägbeskrivningarna är endast till för planering. I
            verkligheten kan vägbyggen, trafik, vädret eller annat göra att
            omständigheterna skiljer sig från våra resultat, och du bör planera
            din rutt efter det. Var uppmärksam på alla skyltar eller meddelanden
            som rör din rutt.
          </p>
        </div>
      </div>
    </section>
  </VueHtml2pdf>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VueHtml2pdf from 'vue-html2pdf'
  import BaseSign from '@/components/BaseSign'
  import RouteGraphic from '@/components/RouteGraphic'

  export default {
    components: {
      VueHtml2pdf,
      BaseSign,
      RouteGraphic,
    },
    computed: {
      ...mapGetters([
        'originAddress',
        'destinationAddress',
        'restrictions',
        'restrictionsPerRoute',
        'isMobile',
        'directions',
        'cutoffStepIndex',
        'startDownload',
      ]),
      currentDate() {
        return new Date(Date.now()).toLocaleString('sv-SE').split(' ')[0]
      },
      signs() {
        let entries = Object.entries(this.restrictions)
        let signsArray = []
        entries.forEach((entry) => {
          if (entry[1] === null) return

          signsArray.push(this.createSign(entry[0]))
        })
        return signsArray
      },
      originSubLocality() {
        let component = this.originAddress.address_components.find((c) =>
          c.types.some((t) => t === 'sublocality')
        )

        if (!component) return ''

        return component.long_name
      },
      originPostalTown() {
        let component = this.originAddress.address_components.find((c) =>
          c.types.some((t) => t === 'postal_town')
        )

        if (!component) return ''

        return component.long_name
      },
      destinationSubLocality() {
        let component = this.destinationAddress.address_components.find((c) =>
          c.types.some((t) => t === 'sublocality')
        )

        if (!component) return ''

        return component.long_name
      },
      destinationPostalTown() {
        let component = this.destinationAddress.address_components.find((c) =>
          c.types.some((t) => t === 'postal_town')
        )

        if (!component) return ''

        return component.long_name
      },
    },
    watch: {
      startDownload(newValue, oldValue) {
        if (newValue) {
          this.$refs.html2Pdf.generatePdf()
          this.$store.commit('setStartDownload', false)
        }
      },
    },
    methods: {
      async beforeDownload({ html2pdf, options, pdfContent }) {
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i)
              pdf.setFontSize(10)
              pdf.setTextColor('#000000')
              pdf.text(
                i + ' / ' + totalPages,
                pdf.internal.pageSize.getWidth() * 0.92,
                pdf.internal.pageSize.getHeight() - 0.3
              )
            }
            return pdf
          })
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i)
              pdf.setFontSize(12)
              pdf.setTextColor('#000000')
              pdf.setFont('Helvetica', 'Bold')
              pdf.text(
                'Tunglast',
                pdf.internal.pageSize.getWidth() * 0.05,
                pdf.internal.pageSize.getHeight() - 0.3
              )
            }
          })
          .save()
      },
      addMargin(index) {
        if (index === this.cutoffStepIndex + 1) {
          return true
        }

        return false
      },
      pageBreak(index) {
        let remaining = index - 3

        if (index === 3 || remaining % 8 === 0) {
          return true
        }

        return false
      },
      stepRestrictionSigns(index) {
        let stepRestrictions = this.restrictionsPerRoute[0][index]
        let signs = null
        stepRestrictions.forEach((r) => {
          let type = r.type.toLowerCase()
          signs = this.signs.filter(
            (s) =>
              s.key.toLowerCase() === type && s.value === r.properties.value
          )
        })

        return signs
      },
      createSign(key) {
        switch (key) {
          case 'roadWidth':
            return {
              label: `${this.restrictions.roadWidth
                .toFixed(1)
                .replace('.', ',')} m`,
              text: 'Begränsning av fri bredd på väg',
              classes: ['square-sign', 'fri-bredd'],
              key: key,
              value: this.restrictions.roadWidth,
            }
          case 'vehicleWidth':
            return {
              label: `${this.restrictions.vehicleWidth
                .toFixed(1)
                .replace('.', ',')}`,
              text: 'Begränsad fordonsbredd',
              classes: ['sign', 'fordonsbredd'],
              key: key,
              value: this.restrictions.vehicleWidth,
            }
          case 'vehicleLength':
            return {
              label: `${this.restrictions.vehicleLength
                .toFixed(1)
                .replace('.', ',')}`,
              text: 'Begränsad fordonslängd',
              classes: ['sign', 'fordonslangd'],
              key: key,
              value: this.restrictions.vehicleLength,
            }
          case 'axlePressure':
            return {
              label: `${this.restrictions.axlePressure
                .toFixed(1)
                .replace('.', ',')} t`,
              text: 'Begränsat axeltryck',
              classes: ['sign', 'axeltryck'],
              key: key,
              value: this.restrictions.axlePressure,
            }
          case 'bogiePressure':
            return {
              label: `${this.restrictions.bogiePressure
                .toFixed(1)
                .replace('.', ',')} t`,
              text: 'Begränsad boggietryck',
              classes: ['sign', 'boggitryck'],
              key: key,
              value: this.restrictions.bogiePressure,
            }
          case 'bearingCapacityClass':
            return {
              label: `${this.restrictions.bearingCapacityClass}`,
              text: 'Bärighetsklass',
              classes: ['sign', 'barighetsklass'],
              key: key,
              value: this.restrictions.bearingCapacityClass,
            }
          case 'grossWeight':
            return {
              label: `${this.restrictions.grossWeight
                .toFixed(1)
                .replace('.', ',')} t`,
              text: 'Begränsad bruttovikt på fordon',
              classes: ['sign', 'bruttovikt'],
              key: key,
              value: this.restrictions.grossWeight,
            }
          case 'environmentZone':
            return {
              label: `${this.restrictions.environmentZone.replace(
                'Miljözon k',
                'K'
              )}`,
              text: 'Klass på miljözon',
              classes: ['sign', 'miljozon'],
              key: key,
              value: this.restrictions.environmentZone,
            }
          case 'heightObstacles':
            return {
              label: `${this.restrictions.heightObstacles
                .toFixed(1)
                .replace('.', ',')} m`,
              text: 'Begränsad fordonshöjd',
              classes: ['sign', 'fordonshojd'],
              key: key,
              value: this.restrictions.heightObstacles,
            }
          default:
            return
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .pdf-template {
    padding: 15px 35px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 15px 0;

    .page-heading {
      font-weight: 700;
      font-family: $font-family;
    }
  }

  .routes {
    padding-left: 30px;
  }

  .route-label {
    padding: 0;
    margin: 0;
    color: $base-color-medium-grey;
    font-weight: 400;
    letter-spacing: 0.4px;
    font-size: 12px;
    font-family: $font-family;
  }

  .route-address {
    margin: 0;
    padding: 5px 0;
    font-weight: bold;
    font-family: $font-family;
  }

  .start {
    margin-bottom: 25px;
  }

  .destination {
    margin-bottom: 10px;
  }

  .bottom-border {
    margin-top: 15px;
    height: 1px;
    width: 75%;
    background: $base-color-grey;
  }

  .locality {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-family: $font-family;
  }

  .town {
    font-family: $font-family;
  }

  p {
    font-family: $font-family;
  }

  .section-divider {
    height: 1px;
    width: 100%;
    background: $base-color-grey;
    margin: 20px 0;
  }

  .signs {
    padding-left: 30px;
    list-style: none;

    li {
      margin: 10px 0;
    }
  }

  .directions {
    max-width: 500px;
  }

  .step {
    padding: 0;

    p {
      margin: 5px 0;
    }

    &.extra-margin {
      margin-top: 40px;
    }
  }

  .step-divider {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .line {
      width: 100%;
      height: 1px;
      background: $base-color-grey;
      display: inline-block;
    }

    .distance {
      display: inline-block;
      word-wrap: normal;
      white-space: nowrap;
      padding: 0 8px;
      font-family: $font-family;
    }
  }

  .results {
    position: relative;
  }
</style>
