<template>
  <div class="terms-modal">
    <div v-mousedown-outside="() => $emit('close')" class="terms-wrapper">
      <div class="terms-header">
        <div class="close-button">
          <CrossIcon @click.native="$emit('close')" />
        </div>
      </div>
      <h5 class="left-align rubrik">Villkor</h5>
      <p class="left-align">
        Tunglast som är en del av Pinpointer AB (556630-4688) har rätt att spara
        dina personuppgifter i syfte att ge dig information om den rutt du
        planerat samt kontakta dig angående erbjudanden och för dig intressanta
        uppdateringar av Tunglast.se eller Pinpointer.se. Vidare äger Pinpointer
        full rätt att använda uppgifter du lämnat, som inte är personuppgifter
        (exempelvis geografiska eller uppgifter om transporten) till att
        utveckla verksamheten. Du kan när som helst avregistrera dig genom att
        klicka på länken som finns mailet eller kontakta oss.
        <br /><br />
        <b>Tunglasts ansvar</b> <br />Tunglast ansvarar inte för att den rutt
        som presenteras anger samtliga hinder som kan finnas längs vägen.
        Trafikskyltarna som presenteras på Tunglast.se bygger på aktuell data
        från Trafikverket men kan exempelvis vid tillfället för transporten ha
        förändrats eller av andra skäl vara felaktiga. Tunglast friskriver sig
        från allt ansvar för eventuella ekonomiska och/eller andra förluster som
        kan uppstå vid en transport planerad via Tunglast.se.
      </p>
    </div>
  </div>
</template>

<script>
  import CrossIcon from '@/components/icons/CrossIcon'

  export default {
    name: 'TermsAndConditionsModal',
    components: {
      CrossIcon,
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';
  .terms-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .rubrik {
      color: #333333;
      font-weight: 700;
      font-family: $font-family;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .terms-header {
    position: relative;
  }

  .terms-wrapper {
    background: $base-color-white;
    padding: 30px 35px 40px 35px;
    width: 90%;
    max-width: 700px;
  }
</style>
