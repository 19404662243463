<template>
  <div class="home">
    <keep-alive>
      <MailChimpModal
        @close="() => closeMailChimpModal()"
        @openTerms="() => openTermsAndConditionsModal()"
        @download="startDownload"
        v-if="showMailChimpModal"
      />
    </keep-alive>
    <TermsAndConditionsModal
      @close="() => closeTermsAndConditionsModal()"
      v-if="showTermsAndConditionsModal"
    />

    <div class="map-wrapper">
      <div v-if="markingOnMap && isMobile" class="position-marker">
        <LocationMarkerIcon />
      </div>
      <GmapMap
        :center="center"
        :zoom="12"
        :options="{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }"
        map-type-id="roadmap"
        style="width: 100%; height: 100%"
        id="map"
        ref="mapRef"
      >
      </GmapMap>

      <CenterPositionButton v-if="!isSearching" :myMap="myMap" />
      <PdfTemplate v-if="restrictions" />
    </div>

    <div class="panels-wrapper" :class="{ minimized: searchIsMinimized }">
      <div class="widgets" v-mousedown-outside="() => clickOutside()">
        <MarkingOnMapBox
          @markingFinished="markingFinished"
          v-if="markingOnMap"
          :searchIsMinimized="searchIsMinimized"
          :myMap="myMap"
        />
        <keep-alive>
          <SearchBox
            v-if="!markingOnMap"
            @search="getDirections()"
            @setActiveField="(field) => setActiveField(field)"
            @markingFinished="markingFinished"
            @toggleMinimized="toggleMinimized"
            @openingModal="openMailChimpModal()"
            @openTerms="() => (showTermsAndConditionsModal = true)"
            :myMap="myMap"
            :searchIsMinimized="searchIsMinimized"
          />
        </keep-alive>
        <AutocompleteBox />
        <GeoLocationBox
          v-if="lastActiveField.length"
          :myMap="myMap"
          :lastActiveField="lastActiveField"
          @startMarking="startMarking()"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import SearchBox from '@/components/SearchBox'
  import AutocompleteBox from '@/components/AutocompleteBox'
  import { gmapApi } from 'gmap-vue'
  import { mapGetters } from 'vuex'
  import GeoLocationBox from '@/components/GeoLocationBox'
  import CenterPositionButton from '@/components/CenterPositionButton'
  import geoLocationMixin from '@/mixins/geoLocationMixin'
  import LocationMarkerIcon from '@/components/icons/LocationMarkerIcon'
  import StandardButton from '@/components/StandardButton'
  import MarkingOnMapBox from '@/components/MarkingOnMapBox'
  import PdfTemplate from '@/components/PdfTemplate'
  import MailChimpModal from '@/components/MailChimpModal'
  import TermsAndConditionsModal from '@/components/TermsAndConditionsModal'

  export default {
    name: 'Home',
    mixins: [geoLocationMixin],
    components: {
      SearchBox,
      GeoLocationBox,
      AutocompleteBox,
      CenterPositionButton,
      LocationMarkerIcon,
      StandardButton,
      MarkingOnMapBox,
      PdfTemplate,
      MailChimpModal,
      TermsAndConditionsModal,
    },
    data() {
      return {
        center: { lat: 57.70887, lng: 11.97456 },
        myMap: null,
        listener: null,
        showMailChimpModal: false,
        showTermsAndConditionsModal: false,
      }
    },
    computed: {
      ...mapGetters([
        'originAddress',
        'destinationAddress',
        'searchQuery',
        'lastActiveField',
        'markingOnMap',
        'restrictions',
        'isSearching',
        'completedSearchIsMinimized',
        'isMobile',
      ]),
      google: gmapApi,
      searchIsMinimized() {
        if (this.restrictions) {
          return this.completedSearchIsMinimized
        }

        return this.lastActiveField.length < 1
      },
    },
    mounted() {
      function debounce(func, wait, immediate) {
        var timeout
        return function () {
          var context = this,
            args = arguments
          var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
          }
          var callNow = immediate && !timeout
          clearTimeout(timeout)
          timeout = setTimeout(later, wait)
          if (callNow) func.apply(context, args)
        }
      }

      let func = debounce(() => {
        if (window.innerWidth > 1024) {
          this.$store.commit('setCompletedSearchIsMinimized', false)
          this.$store.commit('resetLastActiveField')
        }
        this.$store.commit('setWindowWidth', window.innerWidth)
      }, 100)

      window.addEventListener('resize', func)

      this.$refs.mapRef.$mapPromise.then((map) => {
        this.myMap = map
      })
    },
    watch: {
      google(newValue) {
        if (newValue && this.$route.query.coords) {
          let coords = JSON.parse(this.$route.query.coords)
          if (coords) {
            this.getReverseGeocode(coords.origin.lat, coords.origin.lng).then(
              (result) => {
                this.$store.commit(
                  'setOriginAddress',
                  JSON.parse(JSON.stringify(result[0]))
                )
              }
            )
            this.getReverseGeocode(
              coords.destination.lat,
              coords.destination.lng
            ).then((result) => {
              this.$store.commit(
                'setDestinationAddress',
                JSON.parse(JSON.stringify(result[0]))
              )
            })
            this.getDirections(coords.origin, coords.destination)
          }
        }
      },
    },
    methods: {
      startDownload() {
        this.$store.commit('setStartDownload', true)
      },
      closeTermsAndConditionsModal() {
        this.showTermsAndConditionsModal = false
      },
      openTermsAndConditionsModal() {
        this.showTermsAndConditionsModal = true
      },
      closeMailChimpModal() {
        if (this.showTermsAndConditionsModal) {
          return
        }
        this.showMailChimpModal = false
      },
      openMailChimpModal() {
        this.showMailChimpModal = true
        this.$store.commit('setSubmitSuccessful', false)
      },
      toggleMinimized() {
        this.$store.commit(
          'setCompletedSearchIsMinimized',
          !this.completedSearchIsMinimized
        )
      },
      clickOutside() {
        this.$store.commit('resetLastActiveField')
        this.$store.commit('resetSearchQuery')
      },
      startMarking() {
        let marker = new this.google.maps.Marker()
        this.listener = this.myMap.addListener('click', (mapsEvent) => {
          if (this.markingOnMap) {
            marker.setMap(null)
            marker = new this.google.maps.Marker({
              position: {
                lat: mapsEvent.latLng.lat(),
                lng: mapsEvent.latLng.lng(),
              },
              map: this.myMap,
            })
            this.getReverseGeocode(
              mapsEvent.latLng.lat(),
              mapsEvent.latLng.lng()
            ).then((result) => {
              this.$store.commit(
                'setChosenAddress',
                JSON.parse(JSON.stringify(result[0]))
              )
            })
          }
        })
      },
      markingFinished() {
        this.$store.commit('setMarkingOnMap', false)
        this.listener.remove()
      },
      setActiveField(field) {
        this.$store.commit('setLastActiveField', field)
      },
      getDirections(originQueryParameter, destinationQueryParameter) {
        var directionsService = new google.maps.DirectionsService()
        var directionsDisplay = new google.maps.DirectionsRenderer({
          preserveViewport: true,
        })

        const originLat = originQueryParameter
          ? originQueryParameter.lat
          : this.originAddress.geometry.location.lat
        const originLng = originQueryParameter
          ? originQueryParameter.lng
          : this.originAddress.geometry.location.lng
        const destinationLat = destinationQueryParameter
          ? destinationQueryParameter.lat
          : this.destinationAddress.geometry.location.lat
        const destinationLng = destinationQueryParameter
          ? destinationQueryParameter.lng
          : this.destinationAddress.geometry.location.lng

        directionsService.route(
          {
            origin: {
              lat: originLat,
              lng: originLng,
            },
            destination: {
              lat: destinationLat,
              lng: destinationLng,
            },
            travelMode: 'DRIVING',
          },
          (response, status) => {
            if (status === 'OK') {
              this.$store.commit('setLegs', response.routes[0])
              this.$store.commit('setDirections', response.routes[0])

              var bounds = new google.maps.LatLngBounds()
              bounds.extend(response.routes[0].bounds.getNorthEast())
              bounds.extend(response.routes[0].bounds.getSouthWest())
              this.myMap.fitBounds(bounds)

              let center = response.routes[0].bounds.getCenter()
              this.myMap.panTo(center)

              directionsDisplay.setMap(this.myMap)
              directionsDisplay.setDirections(response)

              this.getRestrictions()
            } else {
              window.alert('Directions request failed due to ' + status)
            }
          }
        )

        let coords = {
          origin: { lat: originLat, lng: originLng },
          destination: { lat: destinationLat, lng: destinationLng },
        }
        this.$router
          .replace(
            `?coords=${JSON.stringify(coords)}&p=${
              this.$route.query.p || 'false'
            }`
          )
          .catch((err) => {})
      },
      getRestrictions() {
        this.$store.dispatch('getRestrictions')
        this.$store.dispatch('getRestrictionsPerRoute')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .home {
    background-color: lightblue;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .map-wrapper {
    height: 100%;
    position: relative;
  }

  .position-marker {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    svg {
      width: 32px;
      height: 32px;
      fill: red;
    }
  }

  .panels-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: $base-color-grey;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 10;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.21);

    &.minimized {
      @media (max-width: $breakpoint-desktop) {
        position: static;
        height: unset;
        overflow: unset;
      }
    }

    @media (min-width: $breakpoint-desktop) {
      top: 20px;
      left: 20px;
      width: 400px;
      height: unset;
      background: none;
    }
  }

  .gmnoprint > .gmnoprint div {
    width: 60px !important;
    height: 120px !important;
  }
</style>
