<template>
  <div class="nav-menu">
    <div class="nav-header">
      <div class="logo-wrapper">
        <TunglastIcon />
      </div>
      <button @click="$emit('closeMenu')" class="close-button">
        <CrossIcon />
      </button>
    </div>
    <div class="nav-content">
      <a href="/" class="tunglast-bold"><div>Planera rutt</div></a>
      <router-link to="/About" class="tunglast-bold">
        <div @click="$emit('closeMenu')">Om Tunglast</div>
      </router-link>
      <router-link to="/Faq" class="tunglast-bold">
        <div @click="$emit('closeMenu')">Vanliga frågor</div>
      </router-link>

      <div class="nav-footer">
        <div class="social-media">
          <a href="" class="soMe-icon">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="" class="soMe-icon">
            <font-awesome-icon :icon="['fab', 'linkedin']" />
          </a>
        </div>
        <p>Tunglast Powered by Pinpointer</p>
        <p>Drottninggatan 35, 411 14 Göteborg</p>
        <p>0771-67 10 10, info@tunglast.se</p>
      </div>
    </div>
  </div>
</template>

<script>
  import CrossIcon from '@/components/icons/CrossIcon'
  import TunglastIcon from '@/components/icons/TunglastIcon'

  export default {
    components: {
      CrossIcon,
      TunglastIcon,
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .nav-menu {
    padding: 20px;
    background: $base-color-white;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
    text-align: left;

    @media (min-width: $breakpoint-desktop) {
      width: 400px;
      left: unset;
      right: 0;
    }
  }

  .nav-header {
    margin-bottom: 50px;
  }

  .logo-wrapper {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 111px;
    height: 25px;
    margin: 0 auto;

    @media (min-width: $breakpoint-desktop) {
      display: none;
    }
  }

  .nav-content {
    position: relative;
    height: 85%;

    @media (min-width: $breakpoint-desktop) {
      height: 90%;
    }
  }

  .nav-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 20px;

    @media (min-width: $breakpoint-desktop) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .social-media {
    a {
      padding-right: 5px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: $base-color-black;
    font-size: 1.5rem;
    width: 100%;
    font-weight: 700;

    div {
      height: 50px;
      list-style-type: none;
      display: flex;
      align-items: center;

      &:hover {
        background: $base-color-grey;
      }
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
    }
  }
</style>
