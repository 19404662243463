<template>
  <div class="geolocate--div">
    <div class="geo-button-wrapper">
      <button @click="setDeviceLocation()" id="location">
        Nuvarande plats
        <span v-if="originOrDestination">({{ originOrDestination }})</span>
      </button>
      <div class="spinner-wrapper">
        <Spinner :small="true" v-if="isSearching" />
      </div>
    </div>
    <div class="geo-button-wrapper" v-if="!markingOnMap">
      <button @click="markOnMap" id="mark">Markera på kartan</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { gmapApi } from 'gmap-vue'
  import geoLocationMixin from '@/mixins/geoLocationMixin'
  import Spinner from '@/components/Spinner'

  export default {
    mixins: [geoLocationMixin],
    components: {
      Spinner,
    },
    props: {
      myMap: { type: Object },
    },
    data() {
      return {
        isSearching: false,
        myCoordinates: {
          lat: 0,
          lng: 0,
        },
      }
    },
    computed: {
      ...mapGetters([
        'originAddress',
        'destinationAddress',
        'restrictions',
        'lastActiveField',
        'markingOnMap',
      ]),
      originOrDestination() {
        if (!this.lastActiveField.length) return

        return this.lastActiveField === 'origin' ? 'Start' : 'Mål'
      },
      google: gmapApi,
    },
    methods: {
      markOnMap() {
        this.$store.commit('setMarkingOnMap', true)
        this.$store.commit('setMarkingForField', this.lastActiveField)
        this.$store.commit('resetLastActiveField')

        if (window.innerWidth < 1024) {
          this.$emit('minimize')

          this.myMap.addListener('dragend', () => {
            if (this.markingOnMap) {
              this.setChosenAddress(this.myMap.getCenter())
            }
          })
          this.myMap.addListener('zoom_changed', () => {
            if (this.markingOnMap) {
              this.setChosenAddress(this.myMap.getCenter())
            }
          })
        } else {
          this.$emit('startMarking')
        }
      },
      setChosenAddress(latLng) {
        this.getReverseGeocode(latLng.lat(), latLng.lng()).then((result) => {
          this.$store.commit(
            'setChosenAddress',
            JSON.parse(JSON.stringify(result[0]))
          )
        })
      },
      setDeviceLocation() {
        this.isSearching = true
        let mutation =
          this.lastActiveField === 'destination'
            ? 'setDestinationAddress'
            : 'setOriginAddress'

        if (this.lastActiveField === 'approximateAddress') {
          mutation = 'setApproximateAddress'
        }

        this.getGeoLocation().then((coordinates) => {
          this.getReverseGeocode(coordinates.lat, coordinates.lng).then(
            (results) => {
              const streetAddress = results.find((r) =>
                r.types.some((t) => t === 'street_address')
              )

              if (streetAddress === null) {
                const postalTown = results.find((r) =>
                  r.types.some((t) => t === 'postal_town')
                )

                this.$store.commit(mutation, {
                  formatted_address: `${postalTown.formatted_address}, ${coordinates.lng}, ${coordinates.lat}`,
                  geometry: {
                    location: {
                      lng: coordinates.lng,
                      lat: coordinates.lat,
                    },
                  },
                })
              } else {
                this.$store.commit(
                  mutation,
                  JSON.parse(JSON.stringify(streetAddress))
                )
              }
              this.isSearching = false
              this.$store.commit('resetSearchQuery')
            }
          )
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/styles/_variables.scss';

  .geolocate--div {
    background: $base-color-white;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: solid 10px $base-color-grey;

    .geo-button-wrapper {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-left: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid #aaa;
      }
    }
  }

  .spinner-wrapper {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    right: 10px;
  }

  #location,
  #mark {
    position: relative;
    margin-left: 40px;
    text-align: left;
    border: none;
    background: $base-color-white;
    cursor: pointer;
    padding: 16px 5px;
    width: 100%;

    span {
      pointer-events: none;
    }
  }
  #location::before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    left: -30px;
    top: 12px;
    background-image: url('../assets/svg/markera-karta.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
  }
  #mark::before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    left: -27px;
    top: 12px;
    background-image: url('../assets/svg/nuvarande-plats.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
  }
</style>
