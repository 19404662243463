<template>
  <svg
    id="Lager_2"
    data-name="Lager 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 225 50"
    preserveAspectRatio="xMidYMid meet"
  >
    <polygon
      class="cls-2"
      points="28.37 5.23 3.41 5.23 2.6 16.54 9.32 16.54 7.3 44.86 18.84 44.86 20.85 16.54 27.57 16.54 28.37 5.23"
    />
    <path
      class="cls-2"
      d="M44.19,5.23l-2,28.1a1.21,1.21,0,0,1-.41.82,1.16,1.16,0,0,1-.83.34h-.32a1.07,1.07,0,0,1-.81-.34,1.05,1.05,0,0,1-.29-.82l2-28.1H30l-2.15,30.2a8.35,8.35,0,0,0,2.36,6.65,8.58,8.58,0,0,0,6.57,2.78H43.3a9.71,9.71,0,0,0,7-2.89,11.24,11.24,0,0,0,3.33-7.48L55.72,5.23Z"
    />
    <polygon
      class="cls-2"
      points="72.86 5.23 72.15 15.15 69.02 5.23 57.49 5.23 54.67 44.86 66.2 44.86 66.91 34.95 70.03 44.86 81.57 44.86 84.39 5.23 72.86 5.23"
    />
    <path
      class="cls-2"
      d="M97.36,23.16l-.47,6.6h1.94l-.26,3.57a1.19,1.19,0,0,1-.4.82,1.23,1.23,0,0,1-.86.34h-.52a1,1,0,0,1-.81-.34,1,1,0,0,1-.29-.82l1.18-16.57a1.21,1.21,0,0,1,.4-.82,1.23,1.23,0,0,1,.86-.34h.52a1,1,0,0,1,.81.34,1,1,0,0,1,.29.82l-.25,3.57H111l.74-10.4a4.22,4.22,0,0,0-1.18-3.34,4.4,4.4,0,0,0-3.3-1.36H91a5.12,5.12,0,0,0-3.5,1.36,4.9,4.9,0,0,0-1.65,3.34l-2.15,30.2a4.21,4.21,0,0,0,1.18,3.34,4.32,4.32,0,0,0,3.3,1.39h16.32a5,5,0,0,0,3.5-1.39,4.84,4.84,0,0,0,1.65-3.34l1.21-17Z"
    />
    <path
      class="cls-2"
      d="M157.18,5.23H137.36a.2.2,0,0,0-.19.17l-4,28.13h-9.73l2-28.3H113.87l-2.82,39.63h30.83a.2.2,0,0,0,.19-.16l.78-5.34a.19.19,0,0,1,.19-.16h3.63a.19.19,0,0,1,.19.19v5.28a.19.19,0,0,0,.19.19h10.2a.2.2,0,0,0,.19-.19l-.06-39.25A.2.2,0,0,0,157.18,5.23ZM146.65,29.76h-2.19a.2.2,0,0,1-.19-.22l2.17-15.08a.19.19,0,0,1,.38,0l0,15.08A.19.19,0,0,1,146.65,29.76Z"
    />
    <path
      class="cls-2"
      d="M208.22,5.23h-37.3A10,10,0,0,0,164,8a9.71,9.71,0,0,0-3.3,6.69l-.4,5.66A8.32,8.32,0,0,0,162.6,27a8.6,8.6,0,0,0,6.57,2.77h1.94a1.77,1.77,0,0,1,1.31.54,1.75,1.75,0,0,1,.45,1.36l-.06.94a2,2,0,0,1-.65,1.33,2,2,0,0,1-1.39.56H159.42a.19.19,0,0,0-.19.18l-.72,10a.19.19,0,0,0,.2.2h15.16a10,10,0,0,0,7-2.78,9.73,9.73,0,0,0,3.27-6.65l.4-5.67a8.48,8.48,0,0,0-2.32-6.68,8.77,8.77,0,0,0-6.61-2.75h-1.77a2,2,0,0,1-1.46-.55,1.67,1.67,0,0,1-.47-1.35l.06-.77a2.24,2.24,0,0,1,.78-1.61A2,2,0,0,1,174,15.6h8.76a.17.17,0,0,0,.16.1h6.3a.19.19,0,0,1,.19.21l-2.06,28.75a.19.19,0,0,0,.19.2h11.15a.19.19,0,0,0,.19-.18L201,15.88a.19.19,0,0,1,.19-.18h6.36a.19.19,0,0,0,.19-.17l.7-10.09A.19.19,0,0,0,208.22,5.23Z"
    />
    <path class="cls-2" d="M201.69,41.6h3.23l-.23,3.23h-3.22Z" />
    <path
      class="cls-2"
      d="M211.17,35.72a.58.58,0,0,0-.42.18.6.6,0,0,0-.2.41l0,.3a.51.51,0,0,0,.14.41.52.52,0,0,0,.4.18h.59a2.68,2.68,0,0,1,2.73,2.93l-.12,1.76a3.21,3.21,0,0,1-3.14,2.94h-4.7l.22-3.23h3.53a.65.65,0,0,0,.43-.17.63.63,0,0,0,.19-.42l0-.29a.57.57,0,0,0-.13-.42.57.57,0,0,0-.41-.17h-.59a2.63,2.63,0,0,1-2-.86A2.67,2.67,0,0,1,207,37.2l.13-1.77a3.19,3.19,0,0,1,3.14-2.93h4.69l-.22,3.22Z"
    />
    <path
      class="cls-2"
      d="M223.86,32.5l-.22,3.22h-3.82l-.1,1.48h2.34l-.2,2.93h-2.35l-.1,1.47h3.81L223,44.83h-7.34l.86-12.33Z"
    />
  </svg>
</template>

<script>
  export default {}
</script>

<style>
  .cls-2 {
    fill: #ffffff;
  }
</style>
