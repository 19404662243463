<template>
  <div id="about">
    <div class="hero"></div>
    <section class="blog">
      <h3>Tunglast</h3>
      <h4>Så fungerar Tunglast</h4>
      <p>
        Vi vet att det kan vara snårigt att hålla koll på hur tung last man får
        köra på olika vägar runt om i Sverige. Så borde det inte vara tycker vi
        och därför lanserade vi Tunglast. Här kan du med några enkla knapptryck
        skriva in varifrån du ska åka och vart och därefter få förslag på bästa
        rutten. Där får du information om hur tung last du får köra på de
        vägarna som vi föreslår i form av bärighetsklass, och om det finns några
        begränsningar i lastbredd som kan vara bra för dig att känna till. Du
        ser rutten på kartan på sajten och kan också välja att få den mailad
        till dig så att du har den sparad, eller om du vill dela den med någon
        annan.
        <br />
        <br />
      </p>

      <h4>Drivs av Pinpointer</h4>
      <p>
        Det är Pinpointer, en digital tjänst som matchar schaktmassor och avfall
        från saneringsprojekt med Sveriges största nät av mottagare, som driver
        Tunglast. Pinpointer är den perfekta tjänsten för dig som arbetar med
        deponi, schaktning, bullervallar eller marksanering och gör det enkelt
        att bli av med, transportera eller ta emot schaktmassor, jordmassor
        eller förorenade massor.
      </p>
      <p>
        Vi säkerställer att alla miljökrav uppfylls för dina schaktmassor, att
        all dokumentation är på plats och att du får ett konkurrensmässigt pris.
        Som kund behöver du inte göra avkall på vare sig miljön eller
        lönsamheten. Dessutom kan du använda Pinpointer på alla dina enheter.
        Låter det som en tjänst för dig? Surfa in på
        <a href="https://www.pinpointer.se/" target="_blank"
          >www.pinpointer.se</a
        >
        och läs mer!
      </p>
      <div class="img"></div>
    </section>
    <ArticleFooter />
  </div>
</template>
<script>
  import ArticleFooter from '../components/ArticleFooter'
  export default {
    name: 'about',
    components: {
      ArticleFooter,
    },
  }
</script>
<style scoped lang="scss">
  @import '../assets/styles/_variables.scss';
  #about {
    width: 100vw;
    max-width: 100%;
    position: relative;
    .hero {
      width: 100%;
      height: 600px;
      background-image: url('../assets/images/hero-lastbilbro.jpg');
      background-repeat: no-repeat;
      background-color: gray;
      background-size: cover;
      background-position: top;
      @media (max-width: $breakpoint-mobile) {
        height: 250px;
        background-image: url('../assets/images/hero2-mobil.jpg');
      }
    }
    .blog {
      height: auto;
      width: 926px;
      margin: 0 auto 10rem auto;
      text-align: left;
      @media (max-width: $breakpoint-mobile) {
        padding: 2rem;
        width: 100%;
      }

      .img {
        width: 100%;
        height: 385px;
        background-image: url('../assets/images/bild2-desktop.jpg');
        background-repeat: no-repeat;
        background-color: rgb(187, 187, 187);
        background-size: cover;
        margin-top: 3rem;
        background-position: bottom;
        @media (max-width: $breakpoint-mobile) {
          height: 200px;
          background-image: url('../assets/images/bild2-mobil.jpg');
        }
      }

      h3 {
        font-weight: $font-bold;
        font-size: $font-size-l;
      }

      h4 {
        font-size: 1.5rem;
      }
      p {
        font-size: 18px;
      }
    }
  }
</style>
